<template>
  <nav
    class="item-nav"
    :class="{
    'item-nav--more-open': moreOpen,
    'item-nav--first-open': currentIndex === 0,
  }">
    <template
      v-for="(position, index) in menu"
    >
      <router-link
        v-if="position.display && position.path"
        :key="`position-${position.path}`"
        tag="a"
        class="item-nav__link"
        :to="position.path"
        @click.native="currentIndex = index"
        @mouseenter.native="setRouterLinkClicked(true)"
        @mouseleave.native="setRouterLinkClicked(false)"
      >
        {{ position.name }}
      </router-link>
      <a
        v-else-if="position.display && position.onclick"
        @click="position.onclick"
        :key="`position-${position.path}`"
        class="item-nav__link item-nav__more"
        :class="{ 'item-nav__more--open': moreOpen }"
        v-click-outside="() => (moreOpen = false)"
      >
        {{ position.name }}
      </a>
    </template>
    <ul
      class="item-nav__more-list"
      :class="{ 'item-nav__more-list--open': moreOpen }"
    >
      <li
        class="item-nav__more-item"
        v-for="act in otherActs"
        :key="act.name"
      >
        <router-link
          class="item-nav__link item-nav__more-link"
          :to="act.path"
        >
          {{ act.name }}
        </router-link>
      </li>
    </ul>
    <legislation-switch
      v-if="clientWidth > 767"
      tag="li"
    />
  </nav>
</template>
<script>
import LegislationSwitch from './LegislationSwitch.vue';
import mixinLegislationSwitch from './mixinLegislationSwitch'
import { mapMutations } from 'vuex';

export default {
  components: {
    LegislationSwitch
  },
  props: {
    onlyOther: {
      type: Boolean
    },
    filterOther: {
      type: Boolean
    },
    acts: {
      type: String,
      default: 'otherActs',
    },
    noActs: {
      type: String,
      default: 'noOtherActs',
    },
    notMonitored: {
      type: String,
      default: 'OTHER_ACT_NOT_MONITORED',
    },
    map: {
      type: String,
      default: 'OTHER_ACT_MAP',
    },
  },
  mixins: [
    mixinLegislationSwitch
  ],
  data() {
    return {
      moreOpen: false,
      currentIndex: 0,
    };
  },
  watch: {
    legislation: function () {
      this.currentIndex = 0;
    }
  },
  computed: {
    legislation() {
      return this.$store.state.currentLegislation
    },
    otherActs() {
      let acts = this.$store.getters[`user/${this.acts}`];
      if (this.filterOther) {
        acts = acts.filter(act => !this.$util[this.notMonitored].includes(act));
      }

      return acts.map(act => ({
        name: act,
        path: this.$util[this.map][act],
        display: true,
      }));
    },
    menu () {
      let positions = [];
      if (this.legislation === 'pl') {
        positions.push({
          name: 'ustawy',
          path: 'leges',
          display: () => !this.onlyOther,
        });
        positions.push({
          name: 'rozporządzenia',
          path: 'regulations',
          display: () => !this.onlyOther,
        });
        if (this.otherActs.length === 0 && this.clientWidth > 767) {
          positions.push({
            name: 'inne akty',
            onclick: () => {
              this.$store.commit('toggleGuestModalSecondary', true);
            },
            display: () => !this.onlyOther,
          });
        }
        if (this.clientWidth > 767) {
          positions = positions.concat(this.otherActs);
        } else {
          positions.push({
            name: 'inne akty',
            onclick: () => {
              this.toggleOther();
            },
            display: () => !this.onlyOther,
          });
        }
      } else if (this.legislation === 'eu') {
        positions.push({
          name: 'rozporządzenia',
          path: 'european-union-regulations',
          display: () => !this.onlyOther,
        });
        positions.push({
          name: 'dyrektywy',
          path: 'european-union-directives',
          display: () => !this.onlyOther,
        });
        positions.push({
          name: 'inne',
          path: 'european-union-decisions-and-others',
          display: () => !this.onlyOther,
        });
      }
      return positions;
    }
  },
  methods: {
    ...mapMutations([
      'setRouterLinkClicked'
    ]),
    toggleOther() {
      if (
        !this.$store.getters['user/isLoggedIn'] ||
        this.$store.getters[`user/${this.noActs}`]
      ) {
        this.$store.commit('toggleGuestModalSecondary', true);
        return;
      }
      this.moreOpen = !this.moreOpen;
    }
  },
};
</script>
<style lang="scss" scoped>
.item-nav {
  z-index: 10;
  position: relative;
  display: flex;
  background-color: $gray-6;
  list-style: none;
  padding: 0.3rem;
  padding-right: 0;
  border-radius: 0.4rem;

  @include mq('tablet') {
    border-radius: 0;
    padding: 0;
    display: flex;
    align-items: stretch;
    background-color: transparent;
    // box-shadow: inset 0 -10px 10px -10px #D4D4D4;
    //box-shadow: 10px 10px 20px #D4D4D4;
    &::v-deep .legislation-switches {
      align-items: center;
    }
    &::before, &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      background-color: white;
    }
    &::before {
      z-index: 1;
      height: 66px;
    }
    &::after {
      box-shadow: 0px 10px 30px #D4D4D4;
      z-index: -1;
      height: 18px;
    }
    &--first-open {
      &::before, &::after {
        border-top-left-radius: 0;
      }
    }
  }
}

.item-nav__link {
  font-family: "Open Sans", sans-serif;
  position: relative;
  display: inline-block;
  font-size: 1rem;
  color: $gray-3;
  padding: 0.5rem 1rem;
  border-radius: 0.3rem;
  line-height: 1;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0.2rem;
    height: 1.5rem;
    border-right: 1px solid $gray-3;
    opacity: 0.3;
  }

  &.router-link-active {
    background-color: $primary;
    color: white;

    &::after, &::before {
      display: none;
    }
  }

  &:first-child {
    &::after {
      display: none !important;
    }
  }

  @include mq('tablet') {
    border-radius: 0;
    font-size: .9rem;
    font-weight: bold;
    letter-spacing: 0.02em;
    transition: border-bottom-color 0.15s, color 0.15s;
    padding-inline: 13px;
    padding-block: 10px;
    &.router-link-active {
      background-color: #ffffff;
      color: $primary;
      box-shadow: 0px 0px 20px #D4D4D4;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      position: relative;
      & + .item-nav__link {
        &::after {
          display: none;
        }
      }
      &::before, &::after {
        all: unset;
        content: '';
        position: absolute;
        bottom: 0;
        width: 20px;
        height: 20px;
        border-radius: 100%;
        z-index: 1;
        opacity: 1;
        //animation-name: cornersAnimation;
        //animation-duration: 0.35s;
      }
      &::before {
        left: 100%;
        box-shadow: -10px 10px 0px 0px #FFFFFF;
      }
      &::after {
        right: 100%;
        box-shadow: 10px 10px 0px 0px #FFFFFF;
      }
      //@keyframes cornersAnimation {
      //  from {
      //    opacity: 0;
      //  }
      //  to {
      //    opacity: 1;
      //  }
      //}
    }
    &:not(.router-link-active) {
      &::after {
        background-color: #979797;
        top: 50%;
        transform: translateY(-50%);
        height: 8px;
      }
    }
    &:hover {
      color: $primary;
    }
  }

  @include mq('desktop') {
    font-size: 1.14rem;
  }

}

.item-nav__more {
  padding-right: 2rem;

  &::before {
    @include arrowPseudo($gray-3, 'down', 'small');
    position: absolute;
    right: 1rem;
    margin-top: -0.2rem;
    top: 50%;
    @include mq('tablet') {
      display: none;
    }
  }
}
.item-nav__more--open {
  background-color: $primary;
  color: white;

  &::before {
    border-color: white;
  }
}
.item-nav__more-list {
  display: none;
  position: absolute;
  margin-top: 1rem;
  top: 100%;
  left: 0;
  right: 0;
  padding: 0.5rem;
  padding-bottom: 0;
  background-color: $gray-6;
  border-radius: 0.4rem;
  box-shadow: 0 0.8rem 1rem rgba(black, 0.1);

  &::before {
    content: '';
    position: absolute;
    bottom: 100%;
    margin-bottom: -1px;
    right: 2.5rem;
    border: 0.8rem solid transparent;
    border-bottom-width: 0.6rem;
    border-bottom-color: $gray-6;
  }
}
.item-nav__more-list--open {
  display: block;
}
.item-nav__more-link {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0.5rem;
  background-color: white;
  text-align: center;

  &.router-link-active {
    background-color: $primary;
    color: white;
  }
}
</style>
