<template>
  <component :is="tag" class="legislation-switches">
    <div
      class="legislation-switch"
      :class="{ 'legislation-switch-active': currentLegislation === 'eu' }"
      @click="changeLegislation('eu')"
    >
      <img src="../assets/eu_flag_round.png" />
    </div>
    <div
      class="legislation-switch"
      :class="{ 'legislation-switch-active': currentLegislation === 'pl' }"
      @click="changeLegislation('pl')"
    >
      <img src="../assets/pl_flag_round.png" />
    </div>
  </component>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    tag: String,
  },
  computed: {
    currentLegislation: vm => vm.$store.state.currentLegislation,
    ...mapGetters('user', {
      ue: 'ue',
    }),
  },
  watch: {
    '$route.path': {
      handler: function(newPath, oldPath) {
        const legislation = newPath.includes('european-union') ? 'eu' : 'pl';

        if (
          this.isLegislationChanged(legislation) &&
          this.$route.name !== 'account'
        ) {
          this.changeView(legislation, oldPath);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    isLegislationChanged(legislation) {
      return this.currentLegislation !== legislation;
    },
    changeLegislation(legislation) {
      if (legislation === 'eu' && this.ue.length === 0) {
        this.$store.commit('toggleEuropeModal', true);
        return;
      }
      if (this.isLegislationChanged(legislation)) {
        this.changeView(legislation, true);
      }
      this.$emit('change', legislation);
    },
    changeView(legislation, userClicked = false) {
      this.$store.state.isProjectOpen = false;
      this.$store.commit('setCurrentLegislation', legislation)
      if (userClicked && this.$route.name !== 'account') {
        const nextView =
          legislation === 'pl' ? 'legislation' : 'european-union-regulations';
        this.$router.push({ path: nextView });
      }
    },
  },
};
</script>
<style lang="scss">
.legislation-switches {
  display: flex;
  margin: 0 0 0 auto;
  grid-column-gap: 10px;
  .legislation-switch {
    display: flex;
    border: 1px solid transparent;
    border-radius: 50%;
    background-color: transparent;
    width: 25px;
    height: 25px;
    align-items: center;
    justify-content: center;
    transition: border 0.2s, background-color 0.2s;
    cursor: pointer;
    &.legislation-switch-active,
    &:hover {
      border-color: #0042ff;
    }
    @include mq('tablet') {
      &:hover {
        background-color: #0042ff;
        img {
          border-color: #0042ff;
        }
      }
    }
    img {
      border: 1px solid #bdbdbd;
      border-radius: 50%;
      height: 18px;
      transition: border 0.2s;
    }
  }
}
</style>
