<script>
export default {
  name: 'ListHeader',
  props: {
    searching: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    advanced() {
      return this.$route.query.advanced;
    },
    highlightQuery: function() {
      return this.$route && this.$route.query && this.$route.query.combo
        ? this.$route.query.combo
        : null;
    },
  },
};
</script>

<template>
  <h1 class="heading color-primary heading--bigger">
    <transition name="fade" mode="out-in">
      <div v-if="advanced">
        wyszukiwanie
        <span class="heading__sub">
          zaawansowane
        </span>
      </div>
      <div v-else-if="highlightQuery" key="with-query">
        <span class="heading__sub">
          wyniki dla
        </span>
        „{{ highlightQuery }}”
        <div v-if="searching">
          ładuje
        </div>
      </div>
      <div v-else-if="$store.state.isSearchActive">
        wyszukiwanie
      </div>
      <div v-else key="without-query">
        <slot />
      </div>
    </transition>
  </h1>
</template>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-in-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
