<template>
  <div>
    <div class="mb-8" style="display: flex; align-items: center;">
      <list-header>
        aktualności
      </list-header>
      <legislation-switch v-if="clientWidth <= 767" tag="div" />
    </div>

    <item-nav class="mb-4 mb-tablet-8" />

    <router-view />
  </div>
</template>

<script>
import ItemNav from '../../components/ItemNav';
import LegislationSwitch from '../../components/LegislationSwitch.vue';
import mixinLegislationSwitch from '../../components/mixinLegislationSwitch';
import ListHeader from '@/components/ListHeader.vue';

export default {
  components: {
    ItemNav,
    LegislationSwitch,
    ListHeader,
  },
  mixins: [mixinLegislationSwitch],
};
</script>
<style scoped lang="scss">
.mobile-none {
  display: none;

  @media (min-width: 768px) {
    display: flex;
  }
}
</style>
