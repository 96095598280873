var clientWidth = {
  mounted() {
    window.addEventListener('resize', this.getClientWidth)
  },
  unmounted() {
    window.removeEventListener('resize', this.getClientWidth)
  },
  data () {
    return {
      clientWidth: document.documentElement.clientWidth
    }
  },
  methods: {
    getClientWidth() {
      this.clientWidth = document.documentElement.clientWidth
    },
  },
}

module.exports = clientWidth
